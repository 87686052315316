$pink: #f373af;
$blue: #00abe2;
$light_blue: #04def0;
$font_color: #3d3d3c;

@mixin bt() {
  background: #04def0;
  border-radius: 25px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Geometria-bold'
}

@font-face {
  font-family:'Geometria';
  src: url('fonts/Geometria.otf');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family:'Geometria-bold';
  src: url('fonts/Geometria-Bold.otf');
}

@font-face {
  font-family:'Geometria-medium';
  src: url('fonts/Geometria-Medium.otf');
}
@font-face {
  font-family:'Geometria-extraBold';
  src: url('fonts/Geometria-ExtaBlack.otf');
}
@font-face {
  font-family:'Geometria-ExtraLightItalic';
  src: url('fonts/Geometria-ExtraLightItalic.otf');
}
@font-face {
  font-family:'Geometria-light';
  src: url('fonts/Geometria-Light.otf');
}
@font-face {
  font-family:'Geometria-Heavy';
  src: url('fonts/Geometria-Heavy.otf');
}


