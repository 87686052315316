@font-face {
  font-family: 'Geometria';
  src: url("fonts/Geometria.otf");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'Geometria-bold';
  src: url("fonts/Geometria-Bold.otf"); }

@font-face {
  font-family: 'Geometria-medium';
  src: url("fonts/Geometria-Medium.otf"); }

@font-face {
  font-family: 'Geometria-extraBold';
  src: url("fonts/Geometria-ExtaBlack.otf"); }

@font-face {
  font-family: 'Geometria-ExtraLightItalic';
  src: url("fonts/Geometria-ExtraLightItalic.otf"); }

@font-face {
  font-family: 'Geometria-light';
  src: url("fonts/Geometria-Light.otf"); }

@font-face {
  font-family: 'Geometria-Heavy';
  src: url("fonts/Geometria-Heavy.otf"); }

/*
@mixin font{
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
}

@mixin rounded($raduis){
  border-radius: $raduis;
}

@mixin color{
  color:#fff;
  background-color: red;
}

*/
body {
  font-family: 'Geometria'; }

#header {
  padding-top: 20px;
  padding-bottom: 3px; }
  #header .logo-img {
    float: left;
    position: relative;
    z-index: 25; }
  #header .header-contacts {
    margin-right: 15px;
    width: 356px;
    margin-top: 15px;
    margin-bottom: 8px;
    float: right; }
    #header .header-contacts .header-questions {
      float: left;
      margin-right: 15px;
      margin-bottom: 15px;
      position: relative;
      z-index: 1; }
    #header .header-contacts .question-link {
      color: #f373af;
      text-decoration: underline; }
      #header .header-contacts .question-link:hover {
        text-decoration: none; }
    #header .header-contacts .question {
      color: #04def0;
      border: 2px solid #04def0;
      border-radius: 50%;
      padding: 0 5px;
      font-size: 11px;
      margin-right: 5px; }
    #header .header-contacts .phones {
      position: relative;
      text-align: right; }
    #header .header-contacts .phones i {
      font-weight: bold;
      color: #04def0; }
    #header .header-contacts .more-phones {
      width: 11px;
      height: 12px;
      color: #fff;
      background: #f373af;
      border-radius: 2px;
      display: inline-block;
      font-size: 9px;
      line-height: 11px;
      text-align: center;
      position: relative;
      top: -2px;
      cursor: pointer; }
    #header .header-contacts .add-phones {
      position: absolute;
      top: 28px;
      right: -15px;
      width: auto;
      text-align: left;
      padding: 20px;
      z-index: 3;
      border: 3px solid #1ab2d2;
      background: #fff;
      border-radius: 4px;
      display: none; }
      #header .header-contacts .add-phones i, #header .header-contacts .add-phones img {
        margin-right: 10px;
        color: #3d3d3c; }
      #header .header-contacts .add-phones a {
        color: #3d3d3c;
        text-decoration: none;
        font-size: 13px; }
      #header .header-contacts .add-phones .phone {
        margin-bottom: 5px; }
      #header .header-contacts .add-phones::after {
        background: url("images/triangle.png") no-repeat;
        position: absolute;
        top: -10px;
        right: 10px;
        width: 15px;
        height: 10px;
        display: block;
        content: ''; }
  #header nav {
    float: right;
    background: none;
    border: none;
    box-shadow: none;
    margin-bottom: 16px; }
    #header nav li a {
      text-decoration: none; }
    #header nav li.active a, #header nav li a:hover {
      background-color: #f373af;
      color: #fff;
      border-radius: 18px; }
    #header nav li {
      position: relative;
      padding-bottom: 15px; }
      #header nav li ul.submenu {
        position: absolute;
        top: 100%;
        width: 200%;
        display: none;
        left: 0;
        padding: 28px;
        padding-bottom: 13px;
        border: 2px solid #f373af;
        border-radius: 14px;
        background: #fff;
        z-index: 1; }
        #header nav li ul.submenu li {
          list-style-type: none; }
        #header nav li ul.submenu a {
          color: #f373af;
          text-transform: none;
          font-size: 15px;
          font-family: 'Geometria-medium';
          padding: 0;
          background: none; }
          #header nav li ul.submenu a:hover, #header nav li ul.submenu a.active {
            color: #1ab2d2;
            text-decoration: none; }
    #header nav li:hover ul.submenu {
      display: block; }
    #header nav a {
      font-family: 'Geometria-bold';
      color: #3d3d3c;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 7px;
      text-transform: uppercase; }
    #header nav .collapse.navbar-collapse, #header nav .container-fluid {
      margin-right: -15px; }

.wrapper {
  background: url("images/main_bg2.png") center top no-repeat; }

.model-wrapper .content-wrapper {
  background: url("images/main_bg.png") center bottom no-repeat;
  -webkit-background-size: cover;
  background-size: cover; }

.main-content-wrapper {
  background: url("images/main_bg.png") center bottom no-repeat;
  -webkit-background-size: auto 100%;
  background-size: auto 100%; }

.slider-main-page {
  position: relative;
  background: url("images/main-slide.png") center top no-repeat; }
  .slider-main-page .slide {
    height: 766px;
    outline: none; }
  .slider-main-page .slide .container {
    position: relative;
    height: 100%; }
  .slider-main-page .main-slide-img {
    position: absolute;
    right: 0px;
    bottom: -39px; }
  .slider-main-page .slide-header {
    margin-top: 139px;
    color: #ffffff;
    font-size: 47px;
    font-family: 'Geometria-bold';
    width: 470px;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    line-height: 59px;
    padding-bottom: 17px;
    margin-bottom: 26px; }
  .slider-main-page .slide-introtext {
    font-size: 14px;
    color: #fff;
    width: 470px;
    line-height: 18px;
    margin-bottom: 61px; }
  .slider-main-page .slide-link {
    background: #04def0;
    font-size: 15px;
    text-decoration: none;
    border-radius: 25px;
    padding: 12px 35px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Geometria-bold'; }
    .slider-main-page .slide-link:hover {
      text-decoration: none; }
  .slider-main-page .slick-arrow {
    width: 33px;
    font-size: 0;
    border: none;
    outline: none;
    position: absolute;
    bottom: 53%;
    left: calc(50% - 725px);
    z-index: 2;
    height: 75px;
    background: url("images/main_slider_arr_left.png") center center no-repeat; }
  .slider-main-page .slick-arrow.slick-next {
    left: calc(50% + 725px);
    background: url("images/main_slider_arr_right.png") center center no-repeat; }

.slick-dots {
  position: relative;
  bottom: 163px; }
  .slick-dots li {
    width: 10px;
    height: 10px;
    cursor: pointer;
    border-radius: 50%;
    background: #fff;
    display: inline-block;
    margin-right: 7px; }
    .slick-dots li button {
      visibility: hidden;
      opacity: 0; }
  .slick-dots li.slick-active {
    background: #f075ae; }

.main-tiles {
  padding-bottom: 104px;
  padding-top: 72px; }
  .main-tiles .tile {
    float: left;
    display: block;
    position: relative;
    text-decoration: none;
    margin-bottom: 10px;
    overflow: hidden; }
    .main-tiles .tile:hover img.tile-background {
      transform: scale(1.2, 1.2); }
    .main-tiles .tile img.tile-background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: .5s;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%; }
    .main-tiles .tile .tile-header {
      position: relative;
      z-index: 1;
      margin-left: 28px;
      width: 303px;
      border-bottom: 1px solid #fff;
      padding-bottom: 20px;
      margin-bottom: 10px;
      margin-top: 37px; }
      .main-tiles .tile .tile-header img {
        margin-right: 18px;
        margin-top: -6px; }
      .main-tiles .tile .tile-header span {
        font-family: 'Geometria-extraBold';
        color: #fff;
        font-size: 18px; }
    .main-tiles .tile .tile-text {
      position: relative;
      z-index: 2;
      color: #ffffff;
      font-size: 13px;
      margin-left: 26px;
      margin-right: 26px; }
  .main-tiles .tile-3 {
    width: 383px;
    height: 210px; }
  .main-tiles .tile-3:nth-of-type(2) {
    margin-left: 10px;
    margin-right: 10px; }
  .main-tiles .tile-2 {
    width: 582px;
    height: 300px; }
    .main-tiles .tile-2 .tile-header {
      position: relative;
      z-index: 1;
      margin-left: 26px;
      width: 200px;
      border-bottom: 1px solid #fff;
      padding-bottom: 10px;
      margin-bottom: 10px;
      margin-top: 46px; }
      .main-tiles .tile-2 .tile-header span {
        font-family: 'Geometria-extraBold';
        color: #fff;
        font-size: 18px; }
  .main-tiles .tile-2.right {
    float: right; }
  .main-tiles .tile-4 {
    width: 284px;
    height: 150px;
    justify-content: space-between;
    display: flex;
    align-items: center; }
    .main-tiles .tile-4 .tile-header {
      border-bottom: none;
      position: relative;
      z-index: 1;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      line-height: 200%; }
      .main-tiles .tile-4 .tile-header span {
        font-family: 'Geometria';
        color: #fff;
        font-size: 43px; }
      .main-tiles .tile-4 .tile-header .small-text {
        font-size: 29px; }
  .main-tiles .flex-wrap {
    display: flex;
    justify-content: space-between; }
  .main-tiles .tile-4.middle {
    margin-left: 8px;
    margin-right: 8px; }

.banner {
  position: absolute;
  right: 0;
  top: 300px;
  background: url("images/banner.png") no-repeat;
  height: 344px;
  width: 55px;
  border-radius: 3px 0 0 3px;
  border: 2px solid #fff;
  border-right: none; }

.side-menu {
  position: absolute;
  left: -247px;
  transition: .2s;
  top: 318px;
  border-radius: 0 4px 4px 0;
  background: #0283c8;
  border: 2px solid #fff;
  z-index: 3; }
  .side-menu:hover {
    left: 0; }
  .side-menu ul {
    margin-left: 0;
    padding-left: 0; }
  .side-menu li {
    border-bottom: 1px dashed #5bafdb;
    width: 323px;
    list-style-type: none;
    height: 78px; }
    .side-menu li span.subscribe-btn {
      background: #04def0;
      border-radius: 25px;
      color: #fff;
      text-transform: uppercase;
      font-family: 'Geometria-bold';
      padding: 12px 29px;
      margin-left: 15px;
      margin-right: 2px;
      float: left;
      margin-top: 20px; }
    .side-menu li a {
      display: table;
      width: 100%;
      height: 100%;
      transition: .2s;
      color: #ffffff;
      font-size: 12px;
      font-family: 'Geometria-bold'; }
    .side-menu li span.title {
      border-right: 1px dashed #5bafdb;
      width: 245px;
      height: 100%;
      display: table-cell;
      padding-left: 15px;
      padding-right: 15px;
      vertical-align: middle; }
    .side-menu li span.ico {
      height: 100%;
      display: inline-block;
      width: 70px; }
  .side-menu li:nth-of-type(1) .ico {
    background: url("images/ico1.png") center center no-repeat; }
  .side-menu li:nth-of-type(2) .ico {
    background: url("images/ico2.png") center center no-repeat; }
  .side-menu li:nth-of-type(3) .ico {
    background: url("images/ico3.png") center center no-repeat; }
  .side-menu li:nth-of-type(4) .ico {
    background: url("images/ico4.png") center center no-repeat; }
  .side-menu li:nth-of-type(5) .ico {
    background: url("images/ico5.png") center center no-repeat; }
  .side-menu li:nth-of-type(6) .ico {
    background: url("images/ico6.png") center center no-repeat; }
  .side-menu li:nth-of-type(7) .ico {
    background: url("images/ico7.png") center center no-repeat; }
  .side-menu li:nth-of-type(8) .ico {
    background: url("images/ico8.png") center center no-repeat; }
  .side-menu li:nth-of-type(9) .ico {
    background: url("images/ico9.png") center center no-repeat; }
  .side-menu li:last-of-type {
    border-bottom: none; }
  .side-menu li:hover a {
    background-color: #389ed4;
    text-decoration: none; }
  .side-menu li a.active {
    text-decoration: underline;
    color: #05dff1; }

.side-menu-btn {
  display: none; }

.form-subscribe {
  background: url("images/form_bg.png") center top no-repeat;
  padding-top: 35px;
  padding-bottom: 56px; }
  .form-subscribe .subscribe-header {
    text-align: center;
    color: #fff;
    line-height: 31px;
    margin-bottom: 35px;
    font-size: 24px;
    font-family: 'Geometria-extraBold';
    text-transform: uppercase; }
    .form-subscribe .subscribe-header span {
      position: relative; }
      .form-subscribe .subscribe-header span span {
        color: #f373af; }
  .form-subscribe .subscribe-header > span::after {
    display: block;
    content: '';
    background: url("images/present.png") no-repeat;
    width: 43px;
    height: 44px;
    position: absolute;
    left: -56px;
    top: -7px; }
  .form-subscribe .subscribe-form {
    justify-content: space-between;
    display: flex; }
    .form-subscribe .subscribe-form input[type=text] {
      width: 408px;
      height: 62px;
      border-radius: 28px;
      padding: 0 35px;
      font-size: 16px;
      color: #1f1f1f;
      border: none;
      outline: none;
      font-family: 'Geometria-ExtraLightItalic'; }
    .form-subscribe .subscribe-form input[type=submit] {
      height: 62px;
      border-radius: 28px;
      padding: 0 50px;
      font-size: 18px;
      color: #fff;
      border: none;
      background: #19b1d1;
      font-family: 'Geometria-bold';
      transition: .2s;
      outline: none; }
      .form-subscribe .subscribe-form input[type=submit]:hover {
        background: #0383c8; }

.form-subscribe .subscribe-form input[name=email],
.form-subscribe .subscribe-form input[name=phone] {
  position: relative;
  background: #fff url("images/asterix.png") calc(100% - 23px) center no-repeat; }

#map {
  width: 100%;
  height: 357px; }

.ymaps-2-1-60-balloon__tail {
  left: 95% !important;
  margin-top: -55px;
  z-index: -1; }

.ymaps-2-1-60-balloon__tail:after {
  right: 1px;
  bottom: -10px;
  border-left-color: #fff;
  border-right-width: 0; }

[class*="ymaps-2"][class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -moz-filter: grayscale(100%); }

.ymaps-2-1-60-balloon__content {
  padding: 0; }

.baloon {
  width: 300px; }
  .baloon .baloon-title {
    font-size: 13px;
    font-family: 'Geometria-bold';
    color: #3d3d3c;
    padding-top: 10px;
    line-height: 23px; }
  .baloon .baloon-address {
    font-size: 13px;
    line-height: 23px;
    color: #3d3d3c; }
  .baloon .baloon-img {
    width: 130px;
    height: auto;
    float: left;
    margin-right: 27px; }

#footer {
  padding-top: 78px;
  padding-bottom: 60px; }
  #footer .footer-header {
    text-transform: uppercase;
    color: #3d3d3a;
    font-size: 15px;
    font-family: 'Geometria-bold';
    margin-bottom: 35px; }
  #footer .footer-menu ul {
    padding: 0; }
    #footer .footer-menu ul li {
      list-style-type: none;
      margin-bottom: 6px; }
      #footer .footer-menu ul li a {
        font-size: 13px;
        color: #000;
        text-decoration: none; }
        #footer .footer-menu ul li a:hover, #footer .footer-menu ul li a.active {
          text-decoration: underline; }
  #footer .footer-cont-block {
    float: right; }
  #footer .logo-link img {
    margin-bottom: 30px; }
  #footer .footer-contacts {
    font-family: 'Geometria-bold';
    font-size: 13px;
    color: #000; }
    #footer .footer-contacts div {
      margin-bottom: 7px; }
    #footer .footer-contacts i {
      color: #00abe2;
      font-size: 15px;
      margin-left: 3px;
      margin-right: 3px;
      font-weight: bold; }

.store {
  margin-bottom: 40px;
  line-height: 23px; }
  .store .store-title {
    font-family: 'Geometria-bold';
    font-size: 14px;
    color: #000; }
  .store .store-schedule p {
    margin-bottom: 0; }
  .store .store-link {
    color: #f373af;
    text-decoration: underline; }
    .store .store-link:hover {
      text-decoration: none; }

.store-block {
  margin-bottom: 60px; }

.wrap-header-nav {
  background: url("images/title_bg.png") center center;
  padding-top: 15px;
  padding-bottom: 40px;
  color: #fff; }

.breadcrumbs {
  overflow: hidden;
  margin-bottom: 10px;
  font-size: 12px;
  color: #fff;
  font-size: 12px; }
  .breadcrumbs ul {
    padding-left: 0; }
  .breadcrumbs li {
    float: left;
    list-style-type: none;
    margin-right: 3px; }
    .breadcrumbs li a {
      color: #fff;
      font-size: 12px;
      text-decoration: underline; }
      .breadcrumbs li a:hover {
        text-decoration: none; }

.container h1 {
  font-size: 32px;
  font-family: 'Geometria-light';
  margin-bottom: 0;
  margin-bottom: 0px;
  color: #fff;
  margin-top: 10px; }

.main {
  padding-top: 47px; }

.review-item {
  padding-top: 30px;
  margin-bottom: 70px; }
  .review-item .review-img {
    margin: auto;
    display: block;
    max-width: 100%; }
  .review-item blockquote {
    border: none;
    padding-left: 65px;
    padding-right: 55px;
    padding-top: 45px;
    padding-bottom: 45px;
    position: relative; }
    .review-item blockquote p {
      font-size: 14px;
      color: #3d3d39;
      margin-bottom: 15px;
      font-family: 'Geometria-medium'; }
    .review-item blockquote:before {
      display: block;
      content: '';
      background: url("images/quote_top.png") no-repeat;
      width: 41px;
      height: 30px;
      position: absolute;
      left: 2px;
      top: 16px; }
    .review-item blockquote:after {
      display: block;
      content: '';
      background: url("images/quote_bottom.png") no-repeat;
      width: 40px;
      height: 29px;
      position: absolute;
      right: 50px;
      bottom: 0;
      z-index: 2; }

.articles-list {
  overflow: hidden; }
  .articles-list .article {
    background: #fff; }
  .articles-list .article a {
    border: 1px solid #ebeef0;
    padding: 15px;
    display: block;
    margin-bottom: 30px; }
    .articles-list .article a:hover {
      border: 1px solid #f373af;
      box-shadow: 0 0 2px 0px #f373af;
      text-decoration: none; }
    .articles-list .article a .article-date {
      color: #01aee4;
      font-size: 13px; }
    .articles-list .article a .article-title {
      color: #3d3d39;
      font-size: 14px;
      font-family: 'Geometria-bold';
      margin-top: 5px;
      margin-bottom: 10px; }
    .articles-list .article a .article-img {
      text-align: center;
      margin-bottom: 10px; }
      .articles-list .article a .article-img img {
        max-width: 100%; }
    .articles-list .article a .article-introtext {
      font-size: 13px;
      color: #323232;
      height: 123px;
      overflow: hidden; }

.content {
  color: #3d3d39; }
  .content img {
    max-width: 100%;
    height: auto; }
  .content img.align-left {
    float: left;
    margin-right: 25px;
    margin-bottom: 40px; }
  .content img.align-right {
    float: right;
    margin-left: 25px;
    margin-bottom: 40px; }
  .content a {
    color: #f373af;
    text-decoration: underline; }
    .content a:hover {
      text-decoration: none; }
  .content p {
    font-size: 14px;
    margin-bottom: 15px; }
  .content h3 {
    font-size: 37px;
    clear: both;
    margin-bottom: 20px;
    font-family: 'Geometria-light'; }
  .content ul li {
    list-style-type: none; }

.section-list, .subsection-list {
  margin-left: -5px;
  margin-right: -5px; }
  .section-list .col-lg-4, .subsection-list .col-lg-4 {
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-bottom: 10px; }

.section-item {
  margin: auto;
  position: relative;
  border: 1px solid #e0daed;
  border-radius: 8px;
  overflow: hidden; }
  .section-item .section-bg {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto; }
  .section-item .section-title {
    position: absolute;
    top: 30px;
    width: 100%;
    padding-left: 30px;
    line-height: 21px;
    padding-right: 30px;
    color: #fff;
    font-size: 18px;
    font-family: 'Geometria-extraBold'; }
  .section-item .section-desc {
    opacity: 0;
    transition: .2s;
    background: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px; }
    .section-item .section-desc .section-text {
      font-size: 11px;
      color: #212121;
      text-align: left; }
    .section-item .section-desc .section-gallery {
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 12px;
      margin-bottom: 12px; }
      .section-item .section-desc .section-gallery span img {
        margin: auto; }
      .section-item .section-desc .section-gallery .slick-arrow {
        width: 9px;
        outline: none;
        height: 20px;
        border: none;
        position: absolute;
        top: 32%;
        left: 0;
        font-size: 0;
        background: url("images/slider_arr_left_pink.png") no-repeat; }
        .section-item .section-desc .section-gallery .slick-arrow:hover {
          background: url("images/slider_arr_left_small.png") no-repeat; }
      .section-item .section-desc .section-gallery .slick-next {
        right: 0;
        left: auto;
        width: 9px;
        height: 20px;
        background: url("images/slider_arr_right_pink.png") no-repeat; }
        .section-item .section-desc .section-gallery .slick-next:hover {
          background: url("images/slider_arr_right_small.png") no-repeat; }
    .section-item .section-desc .section-link {
      background: #f373af;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 14px;
      font-family: 'Geometria-bold';
      padding: 10px 20px;
      border-radius: 25px;
      margin: auto;
      text-decoration: none;
      display: inline-block;
      transition: .2s; }
      .section-item .section-desc .section-link:hover {
        background: #05dff1;
        text-decoration: none; }

.section-item:hover .section-desc {
  opacity: 1;
  transition: .2s; }

.subsection-list {
  margin-bottom: 50px; }

.subsection-list .cat {
  position: relative;
  border-radius: 4px; }
  .subsection-list .cat img {
    width: 100%;
    height: auto; }
  .subsection-list .cat .title {
    position: absolute;
    display: inline-block;
    color: #fff;
    font-size: 18px;
    top: 0;
    font-family: 'Geometria-extraBold';
    padding-top: 30px;
    width: 50%;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #fff; }

.product-list {
  overflow: hidden;
  padding-bottom: 60px; }
  .product-list .product {
    position: relative;
    z-index: 1;
    width: 25%;
    max-height: 518px;
    height: 518px;
    float: left;
    background: #fff;
    border: 1px solid #f8edf3;
    text-align: center; }
    .product-list .product a {
      padding-top: 40px;
      background: #fff;
      padding-bottom: 33px;
      position: relative;
      border: 3px solid transparent;
      border-radius: 4px;
      height: 98%;
      display: block; }
      .product-list .product a:hover {
        text-decoration: none;
        border: 3px solid #05c8e7;
        height: auto; }
    .product-list .product .wrap-img {
      position: relative;
      height: 244px;
      margin-bottom: 35px; }
      .product-list .product .wrap-img img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto; }
      .product-list .product .wrap-img img.label-discount {
        z-index: 1;
        right: 20px;
        top: -26px;
        bottom: auto;
        left: auto; }
    .product-list .product .product-title {
      font-size: 16px;
      font-family: 'Geometria-medium';
      color: #3d3d3b;
      padding: 0 30px;
      height: 57px;
      overflow: hidden;
      line-height: 18px;
      margin-bottom: 32px; }
    .product-list .product .more {
      font-family: Geometria-bold;
      font-size: 16px;
      color: #ffffff;
      background: #f373af;
      padding: 10px 25px;
      border-radius: 25px;
      text-transform: uppercase;
      margin-top: 35px;
      display: none; }
    .product-list .product .product-price {
      color: #f373af;
      font-size: 18px;
      font-family: 'Geometria-extraBold'; }
    .product-list .product .product-old-price {
      text-decoration: line-through;
      color: #f373af; }
      .product-list .product .product-old-price span {
        color: #000;
        font-size: 14px;
        font-family: 'Geometria-medium'; }

.product:hover {
  border-color: transparent;
  z-index: 5; }

.product:hover .more {
  display: inline-block; }

.pagination {
  clear: both;
  text-align: center;
  display: block; }
  .pagination ul {
    padding-left: 0; }
  .pagination li {
    list-style-type: none;
    margin-right: 5px;
    display: inline-block; }
    .pagination li span.active {
      display: inline-block;
      background: #f373af;
      border-color: #f373af;
      color: #fff;
      font-size: 13px;
      font-family: 'Geometria-medium';
      padding: 3px 10px;
      border-radius: 4px; }
    .pagination li a {
      color: #b0e6f7;
      border: 1px solid #b0e6f7;
      font-family: 'Geometria-medium';
      border-radius: 4px;
      font-size: 13px;
      padding: 3px 8px;
      text-decoration: none; }
      .pagination li a:hover {
        background: #f373af;
        border-color: #f373af;
        color: #fff; }
    .pagination li a.prev, .pagination li a.next {
      border-color: transparent;
      color: #000; }
      .pagination li a.prev:hover, .pagination li a.next:hover {
        color: #fff; }

.product-detail .product-img {
  background: #fff;
  border-radius: 4px;
  padding-bottom: 35px;
  border: 1px solid #f8edf3; }
  .product-detail .product-img .large-img {
    height: 441px;
    position: relative; }
    .product-detail .product-img .large-img .label-discount {
      position: absolute;
      right: 11px;
      top: 24px; }
    .product-detail .product-img .large-img a {
      display: none;
      height: 100%;
      outline: none;
      position: relative; }
      .product-detail .product-img .large-img a:first-of-type {
        display: block; }
      .product-detail .product-img .large-img a img {
        max-height: 80%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: block;
        max-width: 80%;
        width: auto;
        height: auto; }
  .product-detail .product-img .thumbs {
    position: relative;
    text-align: center; }
    .product-detail .product-img .thumbs img {
      height: 60px;
      margin-right: 10px;
      margin-left: 10px; }

.product-detail .product-content .title {
  color: #3d3d3a;
  font-size: 37px;
  font-family: 'Geometria-light';
  line-height: 40px;
  margin-bottom: 35px; }

.product-detail .product-content .short-desc {
  font-size: 14px;
  color: #3d3d3a;
  margin-bottom: 52px; }
  .product-detail .product-content .short-desc p {
    margin-bottom: 15px; }

.product-detail .product-content .price-block {
  margin-bottom: 37px; }
  .product-detail .product-content .price-block .product-price {
    font-size: 30px;
    font-family: 'Geometria-Heavy';
    color: #f373af; }
  .product-detail .product-content .price-block .product-old-price {
    color: #f373af;
    text-decoration: line-through; }
    .product-detail .product-content .price-block .product-old-price span {
      font-size: 20px;
      color: #000; }

.product-detail .where-buy {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Geometria-bold';
  padding: 10px 25px;
  border-radius: 25px;
  transition: .2s;
  background: #f373af; }
  .product-detail .where-buy:hover {
    text-decoration: none;
    background: #04def0; }

.product-detail .wrap-tabs {
  margin-top: 40px; }

.product-detail .tab-content {
  border: 2px solid #79d4f1;
  padding: 30px;
  padding-bottom: 40px;
  background: #fff;
  color: #3d3d39; }
  .product-detail .tab-content ul {
    padding-left: 0;
    margin-bottom: 40px; }
    .product-detail .tab-content ul li {
      padding-left: 5px;
      list-style-image: url("images/marker_li.png");
      list-style-position: inside;
      margin-bottom: 10px; }
  .product-detail .tab-content ol {
    padding-left: 0;
    margin-bottom: 40px; }
    .product-detail .tab-content ol li {
      list-style-position: inside;
      margin-bottom: 10px; }

.product-detail .nav-tabs a {
  font-size: 14px;
  color: #01aee4;
  top: 2px;
  position: relative;
  font-family: 'Geometria-extraBold'; }

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent; }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  border-color: #79d4f1;
  border-width: 2px;
  padding-bottom: 8px;
  position: relative;
  color: #01aee4; }
  .nav-tabs > li.active > a:after, .nav-tabs > li.active > a:focus:after, .nav-tabs > li.active > a:hover:after {
    display: block;
    height: 5px;
    width: 100%;
    content: '';
    background: #fff;
    position: absolute;
    bottom: -3px;
    left: 0; }

.nav-tabs > li > a:hover {
  border-color: transparent; }

.related-products {
  margin-top: 80px;
  padding-top: 40px;
  border-top: 3px solid #f8ecf4;
  border-bottom: 3px solid #f8ecf4;
  background: #fff; }
  .related-products .product-list {
    padding-left: 20px;
    padding-right: 20px; }
  .related-products .slick-list {
    padding-bottom: 40px; }
  .related-products .slick-arrow {
    width: 9px;
    outline: none;
    height: 20px;
    border: none;
    position: absolute;
    top: 32%;
    left: 0;
    font-size: 0;
    background: url("images/slider_arr_left_pink.png") no-repeat; }
    .related-products .slick-arrow:hover {
      background: url("images/slider_arr_left_small.png") no-repeat; }
  .related-products .slick-next {
    right: 0;
    left: auto;
    width: 9px;
    height: 20px;
    background: url("images/slider_arr_right_pink.png") no-repeat; }
    .related-products .slick-next:hover {
      background: url("images/slider_arr_right_small.png") no-repeat; }
  .related-products .h3 {
    color: #f373af;
    text-transform: uppercase;
    font-family: 'Geometria-extraBold';
    font-size: 16px; }
  .related-products .product {
    border: none; }
    .related-products .product a {
      border: none;
      outline: none; }
      .related-products .product a:hover {
        border: none; }

.h3 {
  color: #3d3d3b;
  font-size: 16px;
  font-family: 'Geometria-bold';
  margin-bottom: 16px; }

p {
  font-size: 14px;
  color: #3d3d3b; }

.banner-model {
  height: 608px;
  padding-top: 120px;
  margin-bottom: 85px; }
  .banner-model.children {
    background: url("images/banner-children.png") center top no-repeat; }
  .banner-model.women {
    background: url("images/banner-kabluki.png") center top no-repeat; }
  .banner-model.sport {
    background: url("images/banner-sport.png") center top no-repeat; }
  .banner-model.profilact {
    background: url("images/banner-profilact.png") center top no-repeat; }
  .banner-model.pregnat {
    background: url("images/banner-pregnat.png") center top no-repeat; }
  .banner-model h1 {
    font-size: 65px;
    font-family: 'Geometria-bold';
    width: 50%;
    margin-bottom: 76px;
    margin-top: 29px; }
  .banner-model a.order {
    font-size: 18px;
    font-family: 'Geometria-bold';
    color: #ffffff;
    padding: 21px 81px;
    border-radius: 33px;
    background: #05dff1;
    transition: .2s;
    text-decoration: none; }
    .banner-model a.order:hover {
      background: #00abe2;
      text-decoration: none; }

.feature {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 80px; }
  .feature img {
    max-width: 100%; }
  .feature div {
    width: 200px;
    color: #f373af;
    margin: auto;
    margin-top: 20px; }

.problems {
  margin-bottom: 80px; }
  .problems .h3 {
    font-size: 24px;
    color: #f373af;
    text-transform: uppercase;
    margin-bottom: 50px;
    font-family: 'Geometria-extraBold'; }
  .problems .solution .h3 {
    color: #19b2d2; }
  .problems .problems-block {
    padding-left: 105px;
    padding-right: 105px; }
    .problems .problems-block ul {
      padding-left: 0; }
      .problems .problems-block ul li {
        padding-left: 32px;
        background: url("images/bird_pink.png") left top no-repeat;
        font-size: 16px;
        color: #3d3d3a;
        margin-bottom: 18px;
        list-style-type: none;
        font-family: 'Geometria-medium'; }
  .problems .solution ul li {
    background: url("images/pink_blue.png") left top no-repeat; }

.video-block {
  background: url("images/video_bg.png") center top no-repeat;
  padding-top: 47px;
  -webkit-background-size: cover;
  background-size: cover;
  padding-bottom: 55px; }
  .video-block .h3 {
    font-size: 24px;
    font-family: 'Geometria-extraBold';
    color: #3d3d3a;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 58px; }
    .video-block .h3 span {
      position: relative; }
      .video-block .h3 span:after {
        display: block;
        position: absolute;
        content: '';
        left: -70px;
        top: -5px;
        background: url("images/smal_logo.png") no-repeat;
        width: 56px;
        height: 38px; }
  .video-block ol {
    margin-top: 24px;
    padding-left: 20px;
    counter-reset: myCounter; }
    .video-block ol li {
      list-style: none;
      font-size: 16px;
      color: #3d3d3a;
      font-family: 'Geometria-bold';
      margin-bottom: 50px;
      padding-left: 70px;
      position: relative; }
      .video-block ol li:before {
        counter-increment: myCounter;
        content: counter(myCounter);
        color: white;
        font-size: 24px;
        font-family: 'Geometria-bold';
        background: #1ab2d2;
        display: inline-block;
        border: 3px solid #7edbef;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        left: -20px;
        margin: -8px 10px 0;
        line-height: 33px;
        width: 40px;
        height: 40px;
        z-index: 2; }
      .video-block ol li:after {
        content: '';
        display: block;
        position: absolute;
        top: 20px;
        left: 7px;
        width: 6px;
        height: 62px;
        background: #1ab2d2; }
  .video-block li:last-of-type:after {
    display: none; }

.models {
  margin-bottom: 100px; }
  .models .slick-slide {
    outline: none; }
  .models .h3 {
    text-align: center;
    color: #3d3d39;
    font-size: 24px;
    font-family: 'Geometria-extraBold';
    margin-top: 56px;
    margin-bottom: 54px; }
  .models .h4 {
    color: #3d3d39;
    font-size: 14px;
    text-align: center;
    width: 80%;
    margin: auto;
    margin-bottom: 40px; }
  .models .model .title {
    color: #3d3d39;
    font-family: 'Geometria-light';
    font-size: 36px;
    padding-right: 100px;
    line-height: 40px;
    margin-bottom: 20px; }
  .models .model img {
    margin-top: 30px; }
  .models .model .intro {
    color: #3d3d39;
    font-size: 14px;
    margin-bottom: 28px;
    line-height: 20px; }
  .models .model .intro-bold {
    font-family: 'Geometria-medium'; }
  .models .model a.order {
    font-size: 18px;
    font-family: 'Geometria-bold';
    color: #ffffff;
    margin-top: 30px;
    padding: 4px 43px;
    border-radius: 25px;
    display: inline-block;
    background: #1ab2d2;
    transition: .2s;
    text-decoration: none; }
    .models .model a.order:hover {
      background: #04def0;
      text-decoration: none; }

.models .slick-arrow, .reviews-block .slick-arrow {
  width: 15px;
  outline: none;
  height: 20px;
  border: none;
  position: absolute;
  top: 43%;
  left: 0;
  font-size: 0;
  background: url("images/slider_arr_left_pink.png") no-repeat; }
  .models .slick-arrow:hover, .reviews-block .slick-arrow:hover {
    background: url("images/slider_arr_left_small.png") no-repeat; }

.reviews-block .slick-arrow {
  top: 40%; }

.reviews-block .h3 {
  text-align: center;
  color: #3d3d39;
  font-size: 24px;
  font-family: 'Geometria-extraBold';
  margin-top: 55px;
  margin-bottom: 54px; }

.reviews-block .slick-dots {
  bottom: 0;
  margin-top: 40px;
  margin-bottom: 70px; }

.reviews-slider .review-item {
  margin-bottom: 0; }

.models .slick-next, .reviews-block .slick-next {
  right: 0;
  left: auto;
  width: 9px;
  height: 20px;
  background: url("images/slider_arr_right_pink.png") no-repeat; }
  .models .slick-next:hover, .reviews-block .slick-next:hover {
    background: url("images/slider_arr_right_small.png") no-repeat; }

.models .slick-dots, .reviews-block .slick-dots {
  bottom: -54px;
  width: 100px;
  text-align: center; }
  .models .slick-dots li:not(.slick-active), .reviews-block .slick-dots li:not(.slick-active) {
    background: #1ab2d2; }

.models .slick-slide, .reviews-block .slick-slide {
  outline: none; }

.reviews-block .reviews-slider {
  padding-left: 30px;
  padding-right: 30px; }

.block-why {
  background: #fdf9fb;
  background: linear-gradient(to right, #fbf5f8 0%, #fff 50%, #fbf5f8 100%);
  padding-bottom: 50px; }
  .block-why .h3 {
    text-align: center;
    color: #3d3d39;
    font-size: 24px;
    font-family: 'Geometria-extraBold';
    margin-top: 73px;
    margin-bottom: 69px;
    width: 50%;
    margin-left: auto;
    margin-right: auto; }
  .block-why ul {
    padding-left: 110px; }
    .block-why ul li {
      padding-left: 32px;
      background: url("images/pink_blue.png") left top no-repeat;
      font-size: 14px;
      color: #3d3d3a;
      margin-bottom: 30px;
      list-style-type: none;
      font-family: 'Geometria-medium'; }
  .block-why .ask {
    border-radius: 25px;
    color: #fff;
    background: #f373af;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Geometria-bold';
    padding: 10px 25px;
    text-decoration: none;
    display: inline-block;
    margin-left: 140px; }
    .block-why .ask:hover {
      text-decoration: none;
      background: #04def0; }

iframe {
  max-width: 100%; }

#form-message {
  position: fixed;
  top: 30%;
  left: calc(50% - 150px);
  background: #fff;
  border: 1px solid #f373af;
  border-radius: 25px;
  z-index: 95;
  padding: 20px;
  display: none; }

@media (max-width: 1600px) {
  .slider-main-page .slick-arrow {
    left: calc(50% - 600px); }
  .slider-main-page .slick-arrow.slick-next {
    left: calc(50% + 600px); } }

@media (max-width: 1200px) {
  .slider-main-page .slick-arrow,
  slider-main-page .slick-arrow.slick-next {
    display: none !important; } }

@media (max-width: 1200px) {
  .section-item .section-desc .section-text {
    font-size: 15px; }
  #header nav a {
    text-decoration: none;
    margin-left: 2px;
    font-size: 13px;
    padding: 10px 6px; }
  .main-tiles .tile-3 {
    width: 316px; }
  .main-tiles .tile-2 {
    width: 49.5%; }
  .main-tiles .tile-4 {
    width: 24.5%; }
  .form-subscribe .subscribe-form input[type=text] {
    width: 308px; }
  #footer .logo-link, #footer .footer-contacts {
    padding-left: 30px; }
  .slider-main-page .slide-header {
    margin-top: 50px; }
  .slider-main-page .main-slide-img {
    width: 480px;
    height: auto;
    bottom: -20px; }
  .video-block ol {
    padding-left: 0; }
  .video-block ol li {
    font-size: 12px; } }

@media (max-width: 990px) {
  .section-item {
    max-width: 355px; }
  .models .model img {
    max-width: 100%; }
  .models .model .title {
    font-size: 30px; }
  .models .model .intro {
    font-size: 12px; }
  .problems .problems-block {
    padding-left: 0;
    padding-right: 0; }
  .cat-page.product-list .product {
    width: 33.3%; }
  .section-item .section-desc {
    padding-top: 15px; }
  .section-item .section-desc .section-text {
    font-size: 11px; }
  .slider-main-page .main-slide-img {
    bottom: -25px; }
  #header .logo-img {
    margin-bottom: 25px; }
  #header nav {
    float: none; }
  .banner {
    background: url("images/banner_small.png") no-repeat;
    width: 49px;
    height: 49px;
    border-radius: 4px 0 0 4px;
    background-size: 100% 100%; }
  .side-menu {
    left: -327px; }
    .side-menu.active {
      left: 0; }
  .side-menu-btn {
    display: block;
    content: '';
    width: 55px;
    height: 55px;
    background: url("images/side_menu_small.png");
    position: absolute;
    left: 100%;
    top: -2px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 2px; }
    .side-menu-btn.active {
      background: url("images/side_menu_small_open.png"); }
  .main-tiles {
    padding-bottom: 40px; }
  .main-tiles .tile-3 {
    width: 33%;
    height: auto; }
  .main-tiles .tile .tile-text {
    display: none; }
  .main-tiles .tile-3 .tile-header {
    border-bottom: none;
    position: absolute;
    top: 0;
    margin-top: 0;
    padding-left: 25px;
    height: 100%;
    width: 100%;
    margin-left: 0;
    margin-top: 41px; }
  .main-tiles .tile img.tile-background {
    position: relative; }
  .main-tiles .tile-3 .tile-header img {
    float: left; }
  .main-tiles .tile-3 .tile-header span {
    font-size: 16px;
    display: block;
    width: 140px;
    float: left; }
  .main-tiles .tile-3:nth-of-type(2) .tile-header span {
    position: relative;
    top: 7px; }
  .main-tiles .tile-3:nth-of-type(2) {
    margin-left: 0.5%;
    margin-right: 0.5%; }
  .main-tiles .tile-2 {
    width: 100%; }
  .main-tiles .tile-2 .tile-header {
    position: absolute;
    top: 0;
    margin-left: 70px; }
  .main-tiles .flex-wrap {
    margin-left: -5px;
    margin-right: -5px;
    display: block; }
  .main-tiles .tile-4 {
    width: calc(50% - 10px);
    display: block;
    height: auto;
    margin: 0 5px 10px; }
  .main-tiles .tile-4 img.tile-background {
    position: relative;
    z-index: 1; }
  .main-tiles .tile-4 .tile-header {
    position: absolute;
    top: 20px;
    z-index: 2;
    top: 34px; }
    .main-tiles .tile-4 .tile-header span {
      font-size: 43px; }
  .main-tiles .tile-4 .tile-header .small-text {
    width: 80%;
    display: block;
    margin: auto;
    line-height: 36px; }
  .form-subscribe .subscribe-form input[type=text],
  .form-subscribe .subscribe-form input[type=submit] {
    width: 30%; }
  .form-subscribe .subscribe-form input[type=submit] {
    padding: 0; }
  .form-subscribe .subscribe-header {
    width: 80%;
    margin: 0 auto 35px; }
  .slider-main-page .slide .container {
    padding-left: 60px;
    padding-right: 60px; }
  .block-why ul {
    padding-left: 0px; }
  .block-why .ask {
    margin-left: 0px; }
  .block-why .h3 {
    width: 100%; } }

@media (max-width: 767px) {
  #header nav {
    width: 100%; }
  .models .model img {
    margin: auto; }
  .video-block iframe {
    margin: auto;
    display: block; }
  .video-block ol {
    max-width: 400px;
    margin: auto; }
  .banner-model {
    padding-left: 30px;
    padding-right: 30px; }
  #header nav {
    float: right;
    clear: left;
    margin-top: -63px; }
  .problems .problems-block ul, .problems .h3 {
    padding-left: 20px;
    padding-right: 20px; }
  .cat-page.product-list .product {
    width: 50%; }
  .articles-list .article a .article-introtext {
    height: auto; }
  .slider-main-page .main-slide-img {
    bottom: -17px;
    right: 20%;
    width: 300px; }
  .main-tiles .tile-4, .main-tiles .flex-wrap, .main-tiles .tile-3:nth-of-type(2) {
    margin-left: 0;
    margin-right: 0; }
  .form-subscribe {
    -webkit-background-size: cover;
    background-size: cover; }
    .form-subscribe .subscribe-form {
      display: block; }
      .form-subscribe .subscribe-form input[type=text], .form-subscribe .subscribe-form input[type=submit] {
        width: 90%;
        float: none;
        display: block;
        margin: 0 auto 20px; }
  .main-tiles .tile-2 {
    height: auto; }
  .main-tiles .tile-3, .main-tiles .tile-4 {
    width: 100%; }
    .main-tiles .tile-3 .tile-header, .main-tiles .tile-4 .tile-header {
      margin-top: 22%;
      font-size: 18px;
      top: 0; }
  .main-tiles .tile-4 .tile-header {
    margin-top: 20%; }
  .main-tiles .tile-2 .tile-header {
    margin-left: 40px; }
  #header .header-contacts .question-link {
    font-size: 12px; }
  #header .header-contacts .phones {
    font-size: 16px; }
  #header nav {
    background: #fff;
    position: relative;
    z-index: 9; }
  .navbar-default .navbar-toggle {
    margin-top: 0px;
    border: none;
    float: right;
    margin-right: 20px;
    display: block; }
  .navbar-default .navbar-toggle:not(.collapsed) {
    float: right; }
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background: none; }
  .navbar-default .navbar-toggle .icon-bar {
    background: #f373af;
    width: 93px;
    height: 6px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 8px; }
  .navbar-default .navbar-toggle:not(.collapsed) .icon-bar:nth-of-type(3) {
    opacity: 0; }
  .navbar-default .navbar-toggle:not(.collapsed) .icon-bar:nth-of-type(2) {
    top: 20px;
    transform: rotate(45deg); }
  .navbar-default .navbar-toggle:not(.collapsed) .icon-bar:nth-of-type(4) {
    bottom: 7px;
    transform: rotate(-45deg); }
  .slider-main-page .slide-header {
    font-size: 28px;
    line-height: 34px;
    margin-top: 40px; }
  .slider-main-page .slick-dots {
    bottom: 423px;
    text-align: right; }
  .side-menu, .banner {
    top: 380px; }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-top: none; }
  #header nav a {
    font-size: 15px;
    color: #3d3d3b;
    background: none;
    padding-left: 10px;
    margin-left: 30px;
    margin-right: 30px; }
  #header nav li.active a, #header nav li a:hover {
    border-radius: 0; }
  #header nav li.active a {
    background: none;
    color: #3d3d3b; }
  #header nav.header-nav li .submenu {
    display: block;
    padding: 0;
    position: relative;
    border: none;
    margin-left: 30px; }
    #header nav.header-nav li .submenu li:last-of-type {
      padding-bottom: 0; }
    #header nav.header-nav li .submenu a {
      padding-left: 10px;
      margin-left: 0; }
  .slider-main-page .slide-introtext,
  .slider-main-page .slide-header {
    max-width: 100%; }
  .block-why .ask {
    margin-bottom: 40px; } }

@media (max-width: 640px) {
  .slider-main-page .slide-introtext {
    margin-bottom: 32px; }
  .slider-main-page .slide-header {
    font-size: 20px; }
  .banner-model a.order {
    padding: 20px 40px; }
  .banner-model h1 {
    font-size: 40px;
    width: 100%; }
  .video-block .h3 span:after {
    display: none; }
  .store {
    width: 100%; }
  .navbar-default .navbar-toggle,
  .navbar-default .navbar-toggle:not(.collapsed) {
    float: none;
    margin: 0 auto; }
  #header nav {
    width: 100%;
    margin-top: 10px; }
  #header .logo-img {
    width: 110px;
    margin-top: 20px; }
  #header .header-contacts .header-questions {
    margin-right: 0;
    float: none;
    text-align: right; }
    #header .header-contacts .header-questions .question {
      margin-right: 0; }
  #header .header-contacts {
    width: 170px; }
  .cat-page.product-list .product {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    float: none; }
  .slider-main-page .main-slide-img {
    right: 10%;
    width: 300px; }
  .footer-cont-block,
  .wrap-footer-menu,
  .stores {
    width: 64%;
    text-align: left;
    margin: 0 18% 30px; }
  #footer .logo-link, #footer .footer-contacts {
    padding-left: 0; }
  .form-subscribe .subscribe-header span::after {
    display: none; }
  .form-subscribe .subscribe-header {
    font-size: 16px;
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  #footer {
    padding-top: 50px; } }

@media (max-width: 400px) {
  #header .header-contacts {
    margin-right: 0; }
  .section-item .section-desc .section-text {
    font-size: 9px; }
  .section-item .section-desc .section-gallery {
    margin-top: 3px;
    margin-bottom: 3px; } }
